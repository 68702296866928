import { Component, Vue } from "vue-facing-decorator";

@Component({
    name: "ContactViewComponent",
    // components: {
    //     VsInput,
    //     VsResult,
    //     FacetCategory,
    //     ActiveFacetCategory,
    // },
})
export default class ContactViewComponent extends Vue {}
