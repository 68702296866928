<!-- detail-dataset.component.vue -->
<template v-if="datasetId">

    <div class="container-fluid banner mz-5">
        <!-- Search input component -->
        <!-- Placeholder text for search input, and triggers onSearch method when the search term changes -->
        <vs-input v-bind:placeholder="'Enter your search term...'" @search-change="onSearch"></vs-input>
    </div>

    <!-- Section that shows the dataset details once the data is loaded -->
    <section v-if="loaded" class="section">
        <div class="container">

            <div class="columns">
                <!-- Main content area displaying dataset details -->
                <div class="column is-8 results_column" style="padding-top: 1.2rem; padding-right: 1rem; padding-left: 1rem">
                     <!-- Card displaying the publication date -->
                    <div class="card">
                        <div class="column dataset__blog-meta">
                            <h2 class="label uppercase">published: {{ getPublishedDate(dataset.server_date_published) }}</h2>
                        </div>
                    </div>
                    <!-- Card displaying the dataset citation -->
                    <div class="card">
                        <div class="column">
                            <label class="label">
                                {{ getCitation() }}
                                <!-- Link to the dataset's DOI if available -->
                                <a v-if="dataset.identifier" target="_blank" class="link-label" v-bind:href="'https://doi.org/' + dataset.identifier.value"
                                    >({{ "https://doi.org/" + dataset.identifier.value }})</a
                                >
                            </label>
                        </div>

                        <!-- Section showing references related to the dataset -->
                        <div v-for="reference in dataset.references" v-bind:key="reference.id" class="columns">
                            <div class="column is-3-desktop is-4-tablet label">{{ reference.relation }}</div>
                            <div class="column is-9-desktop is-8-tablet">
                                {{ reference.type }}:
                                <!-- Link to the reference if it's a DOI -->
                                <a v-if="reference.type === 'DOI'" target="_blank" class="link-label" v-bind:href="reference.value">
                                    {{ reference.value }}
                                </a>
                            </div>
                        </div>

                        <!-- Section showing newer versions of the dataset -->
                        <div v-for="reference in dataset.referenced_by" v-bind:key="reference.id" class="columns">
                            <div class="column is-3-desktop is-4-tablet label">has newer version:</div>
                            <div class="column is-9-desktop is-8-tablet">
                                {{ reference.type }}:
                                <!-- Link to the newer version's DOI -->
                                <a
                                    v-if="reference.type === 'DOI'"
                                    target="_blank"
                                    class="link-label"
                                    v-bind:href="'https://doi.org/' + reference.dataset.identifier.value"
                                >
                                    {{ "https://doi.org/" + reference.dataset.identifier.value }}
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- Card displaying dataset titles -->
                    <div class="card record-elem">
                        <!-- Section for Main and Translated Titles -->
                        <div v-if="dataset.hasOwnProperty('titles')" class="columns">
                            <div class="column is-3-desktop is-4-tablet label">Title/<br />title:</div>
                            <div class="column is-9-desktop is-8-tablet">
                                <p>{{ dataset.MainTitle?.value }}</p>
                                <br />
                                <p v-if="dataset.hasTranslatedTitle()">
                                    {{ dataset.TranslatedTitle?.value }}
                                </p>
                            </div>
                        </div>

                        <!-- Section for dataset abstracts -->
                        <div v-if="dataset.hasOwnProperty('abstracts')" class="columns">
                            <div class="column is-3-desktop is-4-tablet label">
                                Zusammenfassung/<br />
                                abstract:
                            </div>
                            <div class="column is-9-desktop is-8-tablet">
                                <p>{{ dataset.MainAbstract?.value }}</p>
                                <br />
                                <p v-if="dataset.hasTranslatedAbstract()">
                                    {{ dataset.TranslatedAbstract?.value }}
                                </p>
                            </div>
                        </div>
                         <!-- Section for series information -->
                        <div v-if="dataset.hasOwnProperty('abstracts')" class="columns">
                            <div class="column is-3-desktop is-4-tablet label">Serieninformation/<br />series information:</div>
                            <div v-if="dataset.hasSeriesInformationAbstract()" class="column is-9-desktop is-8-tablet">
                                <p>{{ dataset.SeriesInformationAbstract?.value }}</p>
                                <br />
                                <p v-if="dataset.hasTranslatedSeriesInformationAbstract()">
                                    {{ dataset.TranslatedSeriesInformationAbstract?.value }}
                                </p>
                            </div>
                            <div v-else class="column is-9-desktop is-8-tablet">-</div>
                        </div>

                        <!-- Section for method description -->
                        <div v-if="dataset.hasOwnProperty('abstracts')" class="columns">
                            <div class="column is-3-desktop is-4-tablet label">Methodik/<br />method:</div>
                            <div v-if="dataset.hasMethodsAbstract()" class="column is-9-desktop is-8-tablet">
                                {{ dataset.MethodsAbstract?.value }}
                            </div>
                            <div v-else class="column is-9-desktop is-8-tablet">-</div>
                        </div>

                        <!-- Section for dataset files and their details -->
                        <div class="columns">
                            <div class="column is-3-desktop is-4-tablet label">Downloads/<br />downloads:</div>
                            <div v-if="dataset.files.length > 0" class="column is-9-desktop is-8-tablet">
                                <!-- Table showing file details if the embargo has passed -->
                                <table v-if="dataset.hasEmbargoPassed()" id="items" class="table is-bordered is-striped">
                                    <thead>
                                        <tr>
                                            <th class="table-header">Path Name</th>
                                            <th class="table-header">File Ext.</th>
                                            <th class="table-header">File Size</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="file in dataset.files" v-bind:key="file.id">
                                            <td>
                                                <a class="link-label" target="_blank" v-bind:href="portal + file.id"> {{ file.label }} </a>
                                                <br />
                                            </td>
                                            <td>
                                                <span>{{ getExtension(file.path_name) }}</span>
                                            </td>
                                            <td>
                                                <span>{{ formatSize(file.file_size) }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <span v-else>Datensatz hat noch ein gültiges Embargo-Datum.</span>
                            </div>
                        </div>

                        <!-- Section for technical metadata of the dataset -->
                        <div class="columns">
                            <div class="column is-3-desktop is-4-tablet label">Technische Metadaten/<br />technical metadata:</div>
                            <div class="column is-9-desktop is-8-tablet">
                                <p>Persistenter Identifikator: {{ dataset.url }}</p>
                                <p>Status: {{ dataset.server_state }}</p>
                                <p v-if="dataset.hasOwnProperty('user')">Eingestellt von: {{ dataset.user.login }}</p>
                                <p>Herausgeber: {{ dataset.creating_corporation }}</p>
                                <p>Publisher: {{ dataset.publisher_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sidebar displaying additional dataset details -->
                <div id="id-side-bar" class="column is-4 sidebar_column" style="padding-top: 1.2rem; padding-right: 1rem; padding-left: 1rem">
                    
                    <!-- Sidebar card for dataset details like creation year, coverage, language, etc. -->
                    <div class="card">
                        <div class="column">
                            <h2 class="label uppercase">Details</h2>
                            <!-- <data-metrics-badge doi="10.7272/q6g15xs4" display="regular"></data-metrics-badge> -->
                            <!-- <data-metrics-badge doi="10.24341/tethys.209" display="small" v-bind:data-input="post"></data-metrics-badge> -->
                            <!-- <data-metrics-badge v-bind:doi="dataset.identifier.value" display="small"></data-metrics-badge> -->
                        </div>
                    </div>
                    <div class="card">
                        <div class="column">
                            <!-- <h3 class="label uppercase">MAP</h3> -->
                            <Minimap :bounds="dataset.Bounds"></Minimap>
                        </div>
                    </div>
                    <!-- Sidebar card showing dataset keywords -->
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Schlüsselwörter/Keywords</h3>
                            <p v-if="dataset.hasOwnProperty('subjects')">
                                <!-- Iterate through subjects and display them as router links -->
                                <span v-if="accessNotFromDoi()">
                                    <span v-for="(subject, index) in dataset.subjects" :key="subject.value">
                                        <router-link
                                            :to="{ name: 'Search', params: { display: subject.value, type: 'subjects' } }"
                                            class="link-label"
                                        >
                                            {{ subject.value }}
                                        </router-link>
                                        <!-- Add a comma and space after each keyword except the last one -->
                                        <span v-if="index < dataset.subjects.length - 1">, </span>
                                    </span>
                                </span>

                                <span v-else>
                                    {{ dataset.subjects.map((u) => u.value).join(", ") }}
                                </span>
                            </p>
                            <p v-else>-</p>
                        </div>
                    </div>

                    <!-- Sidebar cards displaying year, coverage, language, object type, and other dataset details -->
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Erstellungsjahr/Year</h3>
                            <p>
                                {{ getYear(dataset.server_date_published) }}
                            </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Abdeckung/Coverage</h3>
                            <p style="white-space: pre-wrap">
                                {{ dataset.Coverage }}
                            </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Sprache/Language</h3>
                            <p>
                                {{ getLanguage(dataset.language) }}
                            </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Objekttyp/Object Type</h3>
                            <p>
                                <span><i class="fas fa-file"></i> {{ dataset.type }}</span>
                            </p>
                        </div>
                    </div>
                    <!-- Sidebar card showing dataset licenses -->
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Lizenz/License</h3>
                            <p v-if="dataset.hasLicenses()">
                                <label v-for="license in dataset.licenses" v-bind:key="license.id">
                                    <!-- Link to the appropriate Creative Commons license -->
                                    <span class="normal label">
                                        <a v-if="license.name=='CC-BY-4.0'" target="_blank" class="link-label" v-bind:href="'https://creativecommons.org/licenses/by/4.0/'"
                                            ><i class="fa-brands fa-creative-commons"></i>&nbsp;{{ license.name }}</a
                                        >
                                        <a v-else target="_blank" class="link-label" v-bind:href="'https://creativecommons.org/licenses/by-sa/4.0/'"
                                            ><i class="fa-brands fa-creative-commons"></i>&nbsp;{{ license.name }}</a
                                        >
                                    </span>
                                    <!-- Display Open Access label if the license allows it -->
                                    <span v-if="openAccessLicences.includes(license.name)" class="normal label uppercase"
                                        ><i class="fas fa-lock-open"></i> Open Access</span
                                    >
                                </label>
                            </p>
                        </div>
                    </div>
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Projekt/Project</h3>
                            <p v-if="dataset.project != null">
                                <span>{{ dataset.project.label }}</span>
                            </p>
                            <p v-else>-</p>
                        </div>
                    </div>

                    <!-- Sidebar card showing references -->
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Referenzen/References</h3>
                            <ul v-if="dataset.references.length > 0">
                                <li v-for="(reference, i) in dataset.references" v-bind:key="reference.id">
                                    <!-- Link to reference if it's a DOI or URL -->
                                    <a
                                        v-if="reference.type == 'DOI' || reference.type == 'URL'"
                                        target="_blank"
                                        class="link-label"
                                        v-bind:href="reference.value"
                                    >
                                        {{ `${reference.relation} (${reference.type}): ${reference.label}` }}
                                    </a>
                                    <span v-else class="normal label">
                                        {{ `${reference.relation} (${reference.type}): ${reference.value}` }}
                                    </span>
                                    <span v-if="dataset.references.length > 0 && i < dataset.references.length - 1" class="normal label">--</span>
                                </li>
                            </ul>
                            <p v-else>-</p>
                        </div>
                    </div>
                    <!-- Sidebar card for showing embargo details -->
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Embargo</h3>
                            <p v-if="dataset.embargo_date">
                                <span>{{ getHumanDate(dataset.embargo_date) }}</span>
                            </p>
                            <p v-else>-</p>
                        </div>
                    </div>

                    <!-- Sidebar card for displaying dataset contributors -->
                    <div class="card">
                        <div class="column">
                            <h3 class="label uppercase">Beitragende/Contributor</h3>
                            <p v-if="dataset.hasContributors()">
                                {{ dataset.contributors.map((u) => u.full_name).join(", ") }}
                            </p>
                            <p v-else>-</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- Footer section with partner logos -->
        <div class="container-fluid" style="padding-top: 3.8em">
            <div class="columns">
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="https://www.re3data.org/repository/r3d100013400">
                                <img src="@/assets/site/img/re3-data-logo-mono.jpg" alt="re3 data logo" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 28rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="http://www.geosphere.at/">
                                <img src="@/assets/site/img/geosphere-austria-logo.jpg" alt="logo geosphere austria" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="column col-sm">
                    <div class="card mx-auto" style="width: 18rem; box-shadow: none; border: 0rem">
                        <div class="card-body">
                            <a target="_blank" href="https://www.base-search.net/Search/Results?q=coll:fttethysrdr&refid=dctablede">
                                <img src="@/assets/site/img/base_logo.png" alt="logo base" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import Minimap from "@/components/Minimap.vue";
import DatasetDetailComponent from "./dataset-detail.component";
export default DatasetDetailComponent;
</script>

<style scoped lang="scss">
// @import 'leaflet/dist/leaflet.css';
.section {
    font-size: 0.8rem;
    padding: 0;
}

.card {
    border-radius: 0;
    /* Remove box-shadow for a flat design */
    box-shadow: none;
}

.link-label {
    color: #33cccc;
}

.label {
    /* color: #363636; */
    display: block;
    font-size: 0.8rem;
    font-weight: 700;
}

.label.uppercase {
    text-transform: uppercase;
}

.normal.label {
    font-weight: 400;
}

.column p span i {
    color: #336699;
}

.table-header {
    /* color: #363636; */
    font-size: 0.8rem;
    font-weight: 700;
    background-color: #ccddf1;
}
</style>
