// import { Options, Vue } from "vue-class-component";
import { Component, Vue } from "vue-facing-decorator";

@Component({
    name: "ImprintViewComponent",
    // components: {
    //     VsInput,
    //     VsResult,
    //     FacetCategory,
    //     ActiveFacetCategory,
    // },
})
export default class ImprintViewComponent extends Vue {}
