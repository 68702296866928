<template>
    <div class="container">
        <section class="section">
            <div class="column is-full text-left">
                <h1>Terms and Conditions</h1>
                <!-- <p class="lead">Want to keep updated or need further information?</p>
                <hr class="center-line" /> -->
            </div>
            <!-- <div class="column is-half contact-us-details" style="padding-top: 0; margin-top: 0"> -->
            <div class="column is-full text-left">
                <p>
                    Die Domain-Inhaberin (die GBA), explizit aber die Autoren der Website Tethys RDR (www.tethys.at), übernehmen keine Gewähr für die
                    Aktualität, Richtigkeit und Vollständigkeit der bereitgestellten Informationen. Haftungsansprüche gegen die Autoren, die sich auf Schäden
                    materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung
                    fehlerhafter und unvollständiger Informationen verursacht wurden, werden ausdrücklich ausgeschlossen, soweit nicht Vorsatz oder grobe
                    Fahrlässigkeit der Autoren vorliegt. Die Autoren behalten es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
                    gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
                </p>

                <h2 class="lead">Für den Inhalt verantwortlich</h2>
                <p>
                    Bei direkten oder indirekten Verweisen auf fremde Internetseiten (Links), die außerhalb des Verantwortungsbereichs der Autoren liegen, wird
                    eine Haftung nur dann übernommen, wenn die Autoren von den Inhalten Kenntnis haben, dies zumutbar ist und diese über die technischen Mittel
                    verfügen, um deren Verwendung bei rechtswidrigen Inhalten zu verhindern. Die Autoren erklären hiermit ausdrücklich, dass zum Zeitpunkt der
                    Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Die Autoren haben keinerlei Einfluss auf die aktuelle
                    und zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Deshalb distanzieren sie sich hiermit ausdrücklich von allen Inhalten
                    aller gelinkten/verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt auch für alle innerhalb des eigenen
                    Internetangebotes gesetzten Links und Verweise. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus
                    der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf die verwiesen
                    wurde, nicht derjenige, der via Link auf die betreffende Seite verwiesen wurde.
                </p>

                <h2 class="lead">Urheber- und Kennzeichenrecht</h2>
                <p>
                    Die Autoren sind bestrebt, in allen Publikationen die Urheberrechte aller verwendeten Grafiken und Texte zu beachten, von ihnen selbst
                    erstellte Grafiken und Texte zu nutzen oder auf lizenzfreie Grafiken und Texte zurückzugreifen. Alle innerhalb des Internetangebotes
                    genannten und von Dritten registrierten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen
                    Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu
                    ziehen, dass Markenzeichen nicht den Rechten Dritter unterliegen. Sofern nicht anders angegeben, wird der Inhalt dieser Website unter einer
                    Creative Commons Attribution 3.0-Lizenz lizenziert.
                </p>
                <h2 class="lead">Datenschutz-Bestimmungen</h2>
                <p>
                    Mit dieser Datenschutzerklärung möchte unsere gastgebende Einrichtung, die „GeoSphere Austria“, die Öffentlichkeit über Art, Umfang und
                    Zweck der von uns erhobenen, verwendeten und verarbeiteten personenbezogenen Daten informieren. Darüber hinaus werden betroffene Personen
                    mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte informiert.
                </p>
                <p>
                    Die Nutzung der Internetseiten von Tethys RDR ist ohne Angabe personenbezogener Daten möglich. Wenn eine betroffene Person jedoch unsere
                    Dienste wie das Übermitteln von Datensätzen nutzen oder mit uns in Kontakt treten möchte, ist die Verarbeitung personenbezogener Daten
                    erforderlich. Wenn die Verarbeitung personenbezogener Daten notwendig ist und keine gesetzliche Grundlage für eine solche Verarbeitung
                    besteht, holen wir in der Regel die Zustimmung der betroffenen Person ein. Wir erfassen Informationen von Ihnen, wenn Sie sich auf unserer
                    Website anmelden.
                </p>
                <p>
                    Die Verarbeitung personenbezogener Daten von datenpublizierenden Autoren, deren Co-Autoren sowie Beitragende, wie Name, E-Mail-Adresse und
                    optional die ORCID ID erfolgt stets durch die datenpublizierenden Autoren im Einklang mit der Allgemeinen Datenschutzgrundverordnung (DSGVO)
                    nach Artikel 6, Absatz 1b. Diese Informationen werden zum Zwecke der Anmeldung der datenpublizierenden Autoren und zum Zwecke der Umsetzung
                    der guten wissenschaftlichen Praxis gesammelt. Damit einher geht, dass nach erfolgreichem Publizieren der Daten keine Löschung oder
                    Veränderung der Daten und Metadaten inklusive aller personenbezogenen Daten möglich ist.
                </p>
                <p>
                    Als für die Verarbeitung Verantwortlicher hat Tethys RDR zahlreiche technische und organisatorische Maßnahmen getroffen, um den
                    bestmöglichen Schutz der über diese Website verarbeiteten personenbezogenen Daten sicherzustellen. Internetbasierte Datenübertragungen
                    können jedoch grundsätzlich Sicherheitslücken aufweisen, so dass ein absoluter Schutz möglicherweise nicht gewährleistet ist.
                </p>
                <p>
                    Sie haben grundsätzlich das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch. Dafür wenden
                    Sie sich bitte an die Datenschutzbeauftragte:
                </p>
                <span>Dr. Viktoria Haider</span><br />
                <span>E-Mail: <a href="mailto:datenschutz@geologie.ac.at">datenschutz@geologie.ac.at</a></span
                ><br />
                <p>
                    Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in
                    einer Weise verletzt worden sind, können Sie bei der dafür zuständigen Aufsichtsbehörde eine Beschwerde einlegen:
                </p>
                <span>Österreichische Datenschutzbehörde</span><br />
                <span>Barichgasse 40–42, 1030 Wien</span><br />
                <span>Telefon: +43 1 52 152‐0</span><br />
                <span>E-Mail: <a href="mailto:dsb@dsb.gv.at">dsb@dsb.gv.at</a></span
                ><br />
                <h3 class="lead">Erfassung allgemeiner Daten und Informationen</h3>
                <p>
                    Die Website von Tethys RDR sammelt eine Reihe von allgemeinen Daten und Informationen, wenn eine betroffene Person oder ein automatisiertes
                    System die Website aufruft. Diese allgemeinen Daten und Informationen werden in den Server-Protokolldateien gespeichert. Gesammelt werden
                    (1) die verwendeten Browsertypen und -versionen, (2) das vom zugreifenden System verwendete Betriebssystem, (3) die Website, von der aus ein
                    zugreifendes System auf unsere Website gelangt (sogenannte Referrer), (4) die Sub-websites, (5) Datum und Uhrzeit des Zugriffs auf die
                    Internetseite, (6) eine Internetprotokolladresse (IP-Adresse), (7) der Internetdienstanbieter des zugreifenden Systems und (8) alle anderen
                    ähnlichen Daten und Informationen, die im Falle von Angriffen auf unsere Informationstechnologiesysteme verwendet werden können.
                </p>
                <p>
                    Bei Verwendung dieser allgemeinen Daten und Informationen kann Tethys RDR keine Rückschlüsse auf die betroffene Person ziehen. Diese
                    Informationen werden vielmehr benötigt, um (1) den Inhalt unserer Website korrekt bereitzustellen, (2) den Inhalt unserer Website sowie
                    deren Werbung zu optimieren, (3) die langfristige Überlebensfähigkeit unserer Informationstechnologiesysteme und der Website-Technologie
                    sicherzustellen und (4) den Strafverfolgungsbehörden, welche für die strafrechtliche Verfolgung im Falle eines Cyberangriffs erforderlichen
                    Informationen zur Verfügung stellen. Daher analysiert Tethys RDR anonym erhobene Daten und Informationen statistisch mit dem Ziel, den
                    Datenschutz und die Datensicherheit unserer Institution zu erhöhen und ein optimales Schutzniveau für die von uns verarbeiteten
                    personenbezogenen Daten zu gewährleisten. Die anonymen Daten der Server-Logfiles werden getrennt von allen personenbezogenen Daten einer
                    betroffenen Person gespeichert.
                </p>
                <h3 class="lead">Anmeldung/Registrierung auf unserer Website</h3>
                <p>
                    Die betroffene Person hat die Möglichkeit, sich auf der Website des für die Verarbeitung Verantwortlichen unter Angabe personenbezogener
                    Daten anzumelden (zu registrieren). Welche personenbezogenen Daten an die Steuerung übermittelt werden, bestimmt die jeweilige Eingabemaske
                    des Anmeldeformulars. Die von der betroffenen Person eingegebenen personenbezogenen Daten werden ausschließlich für den internen Gebrauch
                    durch den für die Verarbeitung Verantwortlichen und für eigene Zwecke erhoben und gespeichert. Der Controller kann die Übertragung an einen
                    oder mehrere Prozessoren (z.B. einen wissenschaftlichen Herausgeber) anfordern, die personenbezogene Daten auch für einen internen Zweck
                    verwenden, der dem Controller zuzuordnen ist.
                </p>
                <p>
                    Durch die Registrierung auf der Website des Controllers werden auch die vom Internet Service Provider (ISP) zugewiesene und vom Betroffenen
                    verwendete IP-Adresse – Datum und Uhrzeit der Registrierung – gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass
                    nur so ein Missbrauch unserer Dienste verhindert und gegebenenfalls eine Aufklärung der begangenen Verstöße ermöglicht wird. Insofern ist
                    die Speicherung dieser Daten erforderlich, um die Steuerung abzusichern. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an
                    Dritte weitergegeben, es sei denn, es besteht eine gesetzliche Verpflichtung zur Weitergabe der Daten oder die Übermittlung dient der
                    strafrechtlichen Verfolgung.
                </p>
                <p>
                    Die Registrierung der betroffenen Person mit der freiwilligen Angabe personenbezogener Daten soll es dem für die Verarbeitung
                    Verantwortlichen ermöglichen, die betroffenen Inhalte oder Dienste anzubieten, die aufgrund der Art der betreffenden Angelegenheit nur
                    registrierten Nutzern angeboten werden dürfen. Registrierte Personen können die bei der Registrierung angegebenen personenbezogenen Daten
                    jederzeit ändern oder vollständig aus dem Datenbestand des Verantwortlichen löschen lassen.
                </p>
                <p>
                    Der für die Verarbeitung Verantwortliche teilt jeder betroffenen Person auf Anfrage jederzeit mit, welche personenbezogenen Daten über die
                    betroffene Person gespeichert sind. Darüber hinaus berichtigt oder löscht der für die Datenverarbeitung Verantwortliche personenbezogene
                    Daten auf Verlangen oder unter Angabe der betroffenen Person, sofern keine gesetzlichen Aufbewahrungspflichten bestehen. Ein in dieser
                    Datenschutzerklärung ausdrücklich benannter Datenschutzbeauftragter sowie die gesamten Mitarbeiter des für die Verarbeitung Verantwortlichen
                    stehen der betroffenen Person als Ansprechpartner zur Verfügung.
                </p>
                <h3 class="lead">Kontaktmöglichkeit über die Website</h3>
                <p>
                    Die Website von Tethys RDR enthält Informationen, die einen schnellen elektronischen Kontakt zu unserer Einrichtung sowie eine direkte
                    Kommunikation mit uns ermöglichen. Dazu gehört auch eine allgemeine Adresse der sogenannten elektronischen Post (E-Mail-Adresse). Wenn eine
                    betroffene Person den für die Verarbeitung Verantwortlichen per E-Mail über das Kontaktformular kontaktiert, werden die von der betroffenen
                    Person übermittelten personenbezogenen Daten automatisch gespeichert. Diese von einer betroffenen Person freiwillig übermittelten
                    personenbezogenen Daten werden zum Zwecke der Verarbeitung oder Kontaktaufnahme mit der betroffenen Person gespeichert. Bei Nutzung des
                    Kontaktformulars erfolgt keine Weitergabe dieser personenbezogenen Daten an Dritte.
                </p>
                <h3 class="lead">Übermittlung wissenschaftlicher Daten über die Website</h3>
                <p>
                    Die Website von Tethys RDR enthält eine Webanwendung zum Einreichen von wissenschaftlichen Datensätzen, die in der Tethys -Datenbank
                    gespeichert werden, um sie zu veröffentlichen. Wenn eine betroffene Person mit dem Antrag auf Einreichung wissenschaftlicher Daten Kontakt
                    zum für die Verarbeitung Verantwortlichen aufnimmt, werden die von der betroffenen Person übermittelten personenbezogenen Daten automatisch
                    gespeichert. Diese von einer betroffenen Person freiwillig übermittelten personenbezogenen Daten werden zum Zwecke der Verarbeitung oder
                    Kontaktaufnahme mit der betroffenen Person gespeichert.
                </p>
                <p>
                    Um wissenschaftliche Datensätze erfolgreich bei Tethys RDR einzureichen, muss die betroffene Person einige zusätzliche personenbezogene
                    Daten (z.B. Name der Autoren, der Mitautoren und Beitragende) bereitstellen, damit diese Datensätze in der wissenschaftlichen Gemeinschaft
                    korrekt zitiert werden können. Diese Informationen werden der Öffentlichkeit nach dem Einreichungsprozess über Datensatz-Metadaten (im XML-
                    oder JSON-Format und über die Tethys-Website) zur Verfügung gestellt. Dies ist eine Voraussetzung für das wissenschaftliche Publizieren.
                    Veröffentlichungen zu wissenschaftlichen Daten, einschließlich der oben genannten personenbezogenen Daten, können von Dritten (z.B.
                    Bibliotheken, Datenportalen) unter Verwendung der Metadaten und Datendienste von Tethys RDR verwendet werden.
                </p>
                <h3 class="lead">Nutzung von Content Delivery Networks (CDN)</h3>
                <p>
                    Auf dieser Website hat der Controller Javascript, Schriftarten und Bilder integriert, die von Content Delivery Networks bereitgestellt
                    werden. Ein Content Delivery Network (CDN) ist ein geografisch verteiltes Netzwerk von Proxy-Servern und deren Rechenzentren. Ziel ist es,
                    den Service räumlich auf die Endbenutzer zu verteilen, um eine hohe Verfügbarkeit und Leistung zu gewährleisten. Während dieses technischen
                    Verfahrens können Dritte Kenntnis von personenbezogenen Daten, wie der IP-Adresse der betroffenen Person, erlangen. Die bei Tethys
                    verwendeten CDN-Dienste wurden vom Controller auf DSGVO-Konformität geprüft.
                </p>
                <h3 class="lead">Routinemäßige Löschung und Sperrung personenbezogener Daten</h3>
                <p>
                    Der für die Verarbeitung Verantwortliche verarbeitet und speichert die personenbezogenen Daten der betroffenen Person nur für den Zeitraum,
                    der zur Erreichung des Zwecks der Speicherung erforderlich ist, oder, soweit dies vom europäischen Gesetzgeber oder anderen Gesetzgebern in
                    Gesetzen oder Verordnungen, denen der für die Verarbeitung Verantwortliche unterliegt, gewährt wird.
                </p>
                <p>
                    Ist der Speicherzweck nicht anwendbar oder läuft eine vom europäischen Gesetzgeber oder einem anderen zuständigen Gesetzgeber festgelegte
                    Speicherfrist ab, werden die personenbezogenen Daten gemäß den gesetzlichen Bestimmungen routinemäßig gesperrt oder gelöscht.
                </p>
                <p>
                    Bitte beachten Sie auch unsere rechtlichen Hinweise zu Nutzung, Haftungsausschluss und Haftungsbeschränkungen für die Nutzung unserer
                    Website.
                </p>
                <h3 class="lead">Uns kontaktieren</h3>
                <p>Bei Fragen zu dieser Datenschutzrichtlinie können Sie sich an uns wenden (<a href="/contact" target="_blank">Kontakt</a>).</p>
                <h3 class="lead">Änderungen unserer Datenschutzerklärung</h3>
                <p>
                    Wenn wir uns entscheiden, unsere Datenschutzrichtlinie zu ändern, werden wir diese Änderungen auf dieser Seite veröffentlichen. Diese
                    Richtlinie wurde zuletzt am 25.03.2020 geändert.
                </p>
                <h3 class="lead">OpenStreetMap</h3>
                <p>
                    Diese Seite nutzt über eine Programmierschnittstelle (Application Programming Interface, API) das Open Source-Mapping-Werkzeug
                    „OpenStreetMap“ (OSM). Anbieter ist die OpenStreetMap Foundation. Zur Nutzung der Funktionen von OpenStreetMap ist es notwendig, Ihre
                    IP-Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von OpenStreetMap übertragen und dort gespeichert. Der
                    Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung. Die Nutzung von OpenStreetMap erfolgt im Interesse einer ansprechenden
                    Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein
                    berechtigtes Interesse im Sinne von Artikel 6, Absatz 1 lit. f DSGVO dar. Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
                    Datenschutzseite von OpenStreetMap und auf
                    <a href="http://wiki.openstreetmap.org/wiki/Legal_FAQ" target="_blank">/wiki.openstreetmap.org/wiki/Legal_FAQ</a>.
                </p>
                <h3 class="lead">Rechtswirksamkeit dieses Haftungsausschlusses</h3>
                <p>
                    Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sollten Teile des
                    Textes oder eines Wortlautes nicht, nicht vollständig oder nicht mehr dem geltenden Recht entsprechen, so wird hierdurch die Gültigkeit oder
                    der Inhalt der übrigen Teile des Dokumentes nicht berührt.
                </p>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import TermsViewComponent from "./terms-view-component";
export default TermsViewComponent;
</script>

<style scoped lang="scss">
#app {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 1.5rem;
    }
}
</style>
