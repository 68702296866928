<template>
    <div class="container">
        <section class="section">
            <div class="column is-full text-center">
                <!-- <h1>TETHYS SERVICES</h1> -->
                <p class="lead">Want to keep updated or need further information?</p>
                <hr class="center-line" />
            </div>
            <div class="columns">
                <div class="column contact-us-details" style="padding-top: 0; margin-top: 0">
                    <h3>Our Location</h3>
                    <h5>
                        GeoSphere Austria <br />
                        Neulinggasse 38 <br />
                        1030 Wien <br />
                        <!-- <i class="fas fa-mobile-alt"></i> +43-1-7125674 <br />
                    <i class="fas fa-envelope-open-text"></i>
                    <a class="social-media" href="mailto:repository@geologie.ac.at"> repository@geologie.ac.at</a><br /> -->
                    </h5>

                    <ul class="social-links">
                        <li><i class="fas fa-phone-alt pr-2"></i> +43-1-7125674 <br /></li>
                        <li>
                            <a class="social-media" href="mailto:kontakt@geosphere.at"><i class="fas fa-envelope pr-2"></i> kontakt(at)geosphere.at </a>
                        </li>
                        <li>
                            <a class="social-media" href="https://twitter.com/GeoSphere_AT" target="_blank">
                                <i class="fab fa-twitter"></i> twitter.com/GeoSphere_AT
                            </a>
                        </li>
                        <li>
                            <a class="social-media" href="https://www.facebook.com/GeoSphere.at" target="_blank">
                                <i class="fab fa-facebook"></i> www.facebook.com/GeoSphere.at
                            </a>
                        </li>
                        <li>
                            <a class="social-media" href="https://www.instagram.com/GeoSphere_AT" target="_blank">
                                <i class="fab fa-instagram"></i> www.instagram.com/GeoSphere_AT
                            </a>
                        </li>
                        <li>
                            <a class="social-media" href="https://at.linkedin.com/company/geosphereaustria" target="_blank">
                                <i class="fa-brands fa-linkedin"></i> at.linkedin.com/company/geosphereaustria
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="column contact-us-details" style="padding-top: 0; margin-top: 0">
                    <h3>Contact Person</h3>
                    <h5>
                        Viktoria Haider <br />
                        <a class="social-media" href="mailto:repository@geosphere.at"><i class="fas fa-envelope pr-2"></i> repository(at)geosphere.at </a>
                        <!-- <i class="fas fa-mobile-alt"></i> +43-1-7125674 <br />
                    <i class="fas fa-envelope-open-text"></i>
                    <a class="social-media" href="mailto:repository@geologie.ac.at"> repository@geologie.ac.at</a><br /> -->
                    </h5>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import ContactViewComponent from "./contact-view-component";
export default ContactViewComponent;
</script>

<style scoped lang="scss">
@import "./contact-view-component.scss";
</style>
